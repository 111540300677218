import React from "react";

const Text = () => {
  return (
    <div className="textarea">
      <b>LEARN NEW SKILLS! COME JOIN THE BEST</b>
    </div>
  );
};

export default Text;
