import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <h6>&#169; SMART-TUITION. ALL RIGHTS RESERVED. | Developed in India</h6>
    </div>
  );
};

export default Footer;
