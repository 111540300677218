import React from "react";

const MobileNumberContact = () => {
  return (
    <div className="myCarttoDisplay">
      <h5>
        <i>
          <b> +91-870-725-8661</b>
        </i>
      </h5>
    </div>
  );
};

export default MobileNumberContact;
